.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h5 {
  margin: 24px 0 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -1px;
  font-family: "Ubuntu", sans-serif;
}
.wysiwyg h1:first-child, .wysiwyg h2:first-child, .wysiwyg h3:first-child, .wysiwyg h4:first-child, .wysiwyg h5:first-child, .wysiwyg h5:first-child {
  margin-top: 0;
}
.wysiwyg p {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin: 24px 0 0;
}
.wysiwyg p a {
  margin-top: 0;
}
.wysiwyg p:first-child {
  margin-top: 0;
}
.wysiwyg a {
  color: #0085FF;
  margin-top: 16px;
}
.wysiwyg a:hover {
  text-decoration: underline;
}
.wysiwyg a:first-child {
  margin-top: 0;
}
.wysiwyg img {
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
}
.wysiwyg img:first-child {
  margin-top: 0;
}

@media (max-width: 576px) {
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h5 {
    margin: 16px 0 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
  }
  .wysiwyg h1:first-child, .wysiwyg h2:first-child, .wysiwyg h3:first-child, .wysiwyg h4:first-child, .wysiwyg h5:first-child, .wysiwyg h5:first-child {
    margin-top: 0;
  }
  .wysiwyg p {
    margin-top: 16px;
  }
  .wysiwyg p:first-child {
    margin-top: 0;
  }
}